<template>
  <div
    class="d-flex align-items-center"
    style="height: 36px; gap: 12px"
  >
    <h4
      class="font-weight-bolder mb-0"
      style="min-width: 165px"
    >
      {{ warehouseDatePicker.currentMonthTitle }}
    </h4>

    <div
      class="d-flex"
      style="gap: 12px"
    >
      <b-button
        id="prevWeek"
        class="chevron-btn--custom"
        @click="prevWeek"
      >
        <feather-icon
          icon="LArrowUpLineIcon"
          size="22"
        />
      </b-button>
      <b-button
        id="nextWeek"
        class="chevron-btn--custom"
        @click="nextWeek"
      >
        <feather-icon
          icon="LArrowDownLineIcon"
          size="22"
        />
      </b-button>
    </div>
    <div>
      <b-button
        class="this-month-btn"
        variant="success"
        :disabled="warehouseDatePicker.isSelectedCurrentWeek"
        @click="handleSelectDayOfWeek(new Date())"
      >
        {{ $t('Today') }}
      </b-button>
    </div>
    <div class="h-100">
      <component
        :is="warehouseWeeklyOrderFields['selectDayOfWeek'].type"
        style="width: 400px; margin-left: 5px"
        :value="warehouseDatePicker['selectDayOfWeek']"
        class="bg-white"
        v-bind="getProps('selectDayOfWeek', warehouseWeeklyOrderFields)"
        @keyup="handleSelectDayOfWeek"
      />
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import { nextMonday, previousMonday } from 'date-fns'
import config from '../../config'

export default {
  name: 'ManageWeeklyDate',
  components: {
    BButton,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    warehouseDatePicker() {
      return this.$store.state[this.MODULE_NAME].warehouseDatePicker
    },
  },
  methods: {
    prevWeek() {
      const previousWeekSunday = previousMonday(this.warehouseDatePicker.firstSelectedDayOfWeek)
      this.handleSelectDayOfWeek(previousWeekSunday)
    },
    nextWeek() {
      const nextWeekSunday = nextMonday(this.warehouseDatePicker.lastSelectedDayOfWeek)
      this.handleSelectDayOfWeek(nextWeekSunday)
    },
    handleSelectDayOfWeek(value) {
      this.$store.commit(`${this.MODULE_NAME}/SET_WAREHOUSE_DATE_PICKER`, { value, params: this.params })
    },
  },
  setup() {
    const { warehouseWeeklyOrderFields, MODULE_NAME, getWarehouseOrders } = config()
    return {
      warehouseWeeklyOrderFields,
      MODULE_NAME,
      getProps,
      getWarehouseOrders,
    }
  },
}
</script>

<style lang="scss" scoped>
.chevron-btn--custom {
  min-width: 36px;
  height: 36px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.this-month-btn {
  min-width: 115px;
  height: 36px;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
